
    import CarouselHeadsetCard from "@/components/carousel_cards/carousel_headset_card.vue";
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
            CarouselHeadsetCard
        },
    })

    // *****************************************************************************************************************
    export default class XrHeadsets extends Vue {
        // Methods -----------------------------------------------------------------------------------------------------
        private get createResolutionLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("Horizontal Resolution", "Vertical Resolution", 72, 48);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 0.0, y: 1.0, xanchor: 'left', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private readonly resolutionGraph = {
            // tslint:disable
            traces: [
                {
                    name: 'Quest 1',
                    type: "scatter",
                    x: [1440, 1800, 1832, 2064],
                    y: [1600, 1920, 1920, 2208],
                    marker: {
                        color: 'hsl(30, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        color: 'hsl(30, 100%, 100%)',
                        shape: 'spline',
                        width: 2
                    },
                },  
                {
                    name: 'Quest Pro 1',
                    type: "scatter",
                    x: [1800],
                    y: [1920],
                    marker: {
                        color: 'hsl(60, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        color: 'hsl(60, 100%, 100%)',
                        shape: 'spline',
                        width: 2
                    },
                },
                {
                    name: 'Quest 2',
                    type: "scatter",
                    x: [1832],
                    y: [1920],
                    marker: {
                        color: 'hsl(75, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        color: 'hsl(75, 100%, 100%)',
                        shape: 'spline',
                        width: 2
                    },
                },
                {
                    name: 'Quest 3',
                    type: "scatter",
                    x: [2064],
                    y: [2208],
                    marker: {
                        color: 'hsl(120, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        color: 'hsl(120, 100%, 100%)',
                        shape: 'spline',
                        width: 2
                    },
                },
            ],
            // tslint:enable
        };

        // Data function
        public data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                hololens2ImagePathAndName:
                    require("@/assets/images/hololens_2.png"),

                hpReverbImagePathAndName:
                    require("@/assets/images/hp_reverb.png"),

                magicLeapImagePathAndName:
                    require("@/assets/images/magic_leap.png"),

                oculusGoImagePathAndName:
                    require("@/assets/images/oculus_go.png"),

                oculusQuest2ImagePathAndName:
                    require("@/assets/images/oculus_quest_2.png"),

                pimax5kImagePathAndName:
                    require("@/assets/images/pimax_5k.png"),

                playstationVrImagePathAndName:
                    require("@/assets/images/playstation_vr.png"),

                valveIndexImagePathAndName:
                    require("@/assets/images/valve_index.png"),

                varjoXr1ImagePathAndName:
                    require("@/assets/images/varjo_xr_1.png"),

                viveCosmosImagePathAndName:
                    require("@/assets/images/vive_cosmos.png"),

                viveFocusPlusImagePathAndName:
                    require("@/assets/images/vive_focus_plus.png"),

                viveProEyeImagePathAndName:
                    require("@/assets/images/vive_pro_eye.png"),
            };
        }
    }
